@use '../base/mixins';
@use '../base/variables';

.c-table {
  margin-bottom: variables.$section-margin;
  padding-bottom: variables.$section-padding;
  border-bottom: variables.$border-black;

  &--disable-border {
    padding-bottom: 0;
    border: none;
  }

  .gdds & {
    tbody,
    thead {
      a.gdds-element.noArrow,
      a.gdds-element.arrow {
        font-size: 0.875rem;
      }
    }
  }

  @include mixins.bullet-points-spacing();
}

table {
  border-collapse: collapse;
  width: auto;
  border-radius: 0;
  border-spacing: 2px;
  border-color: variables.$gb_grey_150;
  font-size: 0.75rem;
  overflow-x: auto;

  thead,
  tbody,
  tfoot {
    border: none;
  }

  &.table-clean {
    th,
    td {
      border-bottom: 1px solid transparent;
      border-left: 1px solid transparent;
    }
  }

  th,
  td {
    text-align: left;
    padding: 10px 1.25rem;
    border-bottom: 1px solid variables.$gb_grey_150;
    border-left: 1px solid variables.$gb_grey_150;

    &:first-child {
      border-left: none;
    }
  }

  th {
    font-weight: variables.$font-weight-bold;
    font-size: 0.875rem;
  }

  tbody {
    td {
      &.center {
        text-align: center;
      }

      tr:nth-child(even) {
        background-color: variables.$gb_white;
      }

      a,
      ul,
      ol {
        font-size: 0.75rem;
      }

      ul,
      ol {
        margin-bottom: 0;
      }

      li {
        padding-bottom: 0.3125rem;

        &::before {
          font-weight: normal;
        }
      }
    }
  }

  /* Table variants */
  &.plain {
    tr:nth-child(even) {
      background-color: variables.$gb_white;
    }

    th,
    td {
      hyphens: none;
      min-width: 150px;

      @include mixins.screen-md {
        hyphens: inherit;
        min-width: auto;
      }
    }
  }

  &.colored-line.grey {
    tbody tr:nth-child(even) {
      background-color: variables.$gb_grey_050;
    }
  }

  &.colored-line.light-blue {
    tbody tr:nth-child(even) {
      background-color: var(--gb-brand-primary-light);
    }
  }

  .x-mark {
    // TODO: quick fix - remove this when table data (icon) is restructured
    z-index: 9 !important;
    background-color: transparent !important;

    &:before {
      @include mixins.web20-icon('close');
    }
  }

  &.centered {
    td,
    th {
      text-align: center;
    }
  }

  &.right {
    td,
    th {
      text-align: right;
    }
  }
}

.table-outer-wrapper {
  margin: 25px auto;
  overflow: auto;

  @include mixins.breakpoint(large) {
    height: auto;
  }

  td[rowspan='2']:not(:last-child) {
    border-right: solid 1px #d9d9d9;
  }
}

.table-column--fixed {
  thead {
    position: relative;
    z-index: 10;
  }

  tbody {
    z-index: 5;
  }

  tr th:first-of-type,
  tr td:first-of-type {
    left: 0;
    position: sticky;
    background-color: white;
    background-clip: padding-box;
  }

  tr th:first-of-type {
    &::after {
      box-shadow: 10px -7px 8px -6px rgba(0, 0, 0, 0.2);
      content: '';
      height: 9999%;
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
    }
  }
}

.table-container {
  overflow-y: hidden;
  margin: 0 0 1em;

  &.table-head--fixed {
    max-height: 9.375rem;
    position: relative;
    overflow-y: scroll;

    @include mixins.breakpoint(large) {
      overflow: hidden;
      max-height: none;
    }

    .table {
      width: 100%;

      thead {
        position: sticky;
        z-index: 10;
        top: 0;
        background-color: white;

        @include mixins.breakpoint(large) {
          background-color: unset;
        }

        tr {
          box-shadow: variables.$default-box-shadow;
          vertical-align: inherit;
          border-color: inherit;

          @include mixins.breakpoint(large) {
            box-shadow: none;
          }
        }
      }
      tbody {
        z-index: 5;
      }
    }
  }
}

.table-nordics {
  margin: variables.$spacing-nordics-mobile; // 4rem, 64px

  @include mixins.screen-sm {
    margin: variables.$spacing-nordics-desktop; // 5rem, 80px
  }
}
